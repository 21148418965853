import * as $ from "jquery";
$.fn.ancillaryExpenseDistribution = function () {
    return this.each(function () {
        const $e = $(this);
        $e.find("input[type='radio']").on('click', (e) => {
            recalculate();
        });
        $e.find("input[type='text']").on('focusout', (e) => {
            renderCustomTotal();
            recalculate();
        });
        const recalculate = () => {
            const key = getKey();
            const keyTotal = getKeyTotal(key);
            const expense = getExpense();
            const $amountInputs = $e.find("input[id*='CalculationDistributionsType_distributions'][id$='amount']");
            $amountInputs.each((index, amountInput) => {
                const $amountInput = $(amountInput);
                const $keyValuesContainers = $amountInput
                    .closest(".form-group")
                    .prevAll(".key-values");
                const $keyValuesContainer = $($keyValuesContainers[0]);
                let keyValue = 0.0;
                let periodFactor = 1;
                if (key === 'custom') {
                    const foundKeyValue = $amountInput
                        .closest(".col")
                        .prev()
                        .find("input")
                        .val()
                        .toString()
                        .replace(",", ".");
                    keyValue = parseFloat(foundKeyValue);
                }
                else {
                    const foundKeyValue = $keyValuesContainer
                        .find("[data-key='" + key + "']")
                        .data("key-value");
                    keyValue = parseFloat(foundKeyValue);
                    const periodFactorValue = $amountInput
                        .closest(".col")
                        .parent()
                        .find("[data-period-factor]")
                        .data("period-factor");
                    periodFactor = parseFloat(periodFactorValue);
                }
                const factor = calculateFactor(keyValue, keyTotal, periodFactor);
                const amount = calculateAmount(factor, expense);
                if (!isNaN(amount)) {
                    $amountInput.val(formatAmount(amount));
                }
                else {
                    $amountInput.val(formatAmount(0));
                }
            });
        };
        const calculateAmount = (factor, expense) => {
            return factor * expense;
        };
        const formatAmount = (amount) => {
            return amount.toFixed(2);
        };
        const calculateFactor = (value, total, periodFactor) => {
            return periodFactor * (value / total);
        };
        const getExpense = () => {
            return parseFloat($e.find("[data-expense]").data("expense"));
        };
        const getKey = () => {
            let selected = $e.find("input[name*='[distributionKey]']:checked");
            if (!selected) {
                selected = $e.find("input[name*='[distributionKey]']:first");
            }
            const value = selected.val();
            if (!value) {
                return 'custom';
            }
            return value.toString();
        };
        const getCustomTotal = () => {
            const $a = $e.find("input[id*='distributionKeyValue']");
            let total = 0.0;
            for (let i = 0; i < $a.length; i++) {
                const val = $($a[i])
                    .val()
                    .toString()
                    .replace(",", ".");
                const amount = parseFloat(val);
                if (isNaN(amount)) {
                    continue;
                }
                total += amount;
            }
            return Math.round(total * 100) / 100;
        };
        const renderCustomTotal = () => {
            const total = getCustomTotal();
            $e.find(".distribution-key--total[data-key='custom']").data('total', total);
            $e.find(".distribution-key--total[data-key='custom']").html(total.toString());
        };
        const getKeyTotal = (key) => {
            if (key === 'custom') {
                return getCustomTotal();
            }
            return parseFloat($e.find("[data-key-total][data-key=" + key + "]").data("key-total"));
        };
        renderCustomTotal();
        recalculate();
    });
};
