import * as $ from "jquery";
$.fn.manageDamages = function (options) {
    const settings = {
        ...options
    };
    console.warn('@deprecated to be removed', 'assets/plugins/damage-manager/damageManager.ts:19');
    return this.each(function () {
        let mode = 0;
        const updateProgress = () => {
            $manager.find(".damage-manager--room-detail").each((index, el) => {
                const roomId = $(el).data('room-id');
                $manager.find("a.damage-manager--room[data-room-id='" + roomId + "'] div.progress-bar").css('width', ((countUnhandledAssets(roomId) / countAssets(roomId)) * 100) + "%");
            });
        };
        const countAssets = (roomId) => {
            return $manager.find(".damage-manager--room-detail[data-room-id='" + roomId + "'] select[id$='assetCondition']").length;
        };
        const countUnhandledAssets = (roomId) => {
            $manager.find(".damage-manager--room-detail[data-room-id='" + roomId + "'] input[type=checkbox]").closest('a').removeClass('done');
            $manager.find(".damage-manager--room-detail[data-room-id='" + roomId + "'] input[type=checkbox]:checked").closest('a').addClass('done');
            return $manager.find(".damage-manager--room-detail[data-room-id='" + roomId + "'] input[type=checkbox]:checked").length;
        };
        const $manager = $(this);
        const $roomList = $manager.find(".damage-manager--room-list").first();
        updateProgress();
        $(".damage-manager--room-detail").on("click", (e) => {
            const $assetConditionItem = $(e.target);
            $assetConditionItem.find("input[type=checkbox]").trigger('click');
        });
        $manager.find(".damage-manager--room-detail select[id$='assetCondition']").on('change', (e) => {
            const $select = $(e.target);
            if ($(e.target).val() !== "0" && $(e.target).val() !== "1") {
                const assetId = $select.parent().data('asset-id');
                $("#asset-condition-damage-" + assetId).modal('show');
            }
        });
        $manager.find("a.damage-manager--room").on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const roomId = $(e.target).data('room-id');
            const roomName = $(e.target).data('room-name');
            updateProgress();
            $roomList.hide();
            $(".kt-form__actions").hide();
            $manager.find(".damage-manager--room-detail[data-room-id='" + roomId + "']").css('display', 'flex');
            $(".room-name").html(": " + roomName + " <a class=\"back-to-room-list btn btn-secondary btn-xs\">zurück</a>");
            $(".back-to-room-list").on('click', () => {
                $(".kt-form__actions").show();
                $manager.find(".damage-manager--room-detail").hide();
                $roomList.show();
                $(".room-name").html("");
                updateProgress();
            });
        });
    });
};
