import { getPath } from "../utils";
import Translator from "bazinga-translator";
import * as numeral from 'numeral';
export function render(col) {
    console.warn('@deprecated to be removed', 'assets/plugins/data-table/dataTableFunctions::13');
    switch (col.renderFunction) {
        case 'percentage':
            return renderPercentage(col);
        case 'money':
            return renderMoney(col);
        case 'moneyWithBalance':
            return renderMoneyWithBalanceState(col);
        case 'thumbnail':
            return renderThumbnail(col);
        case 'months':
            return renderMonths(col);
        case 'link':
            return renderLink(col);
        case 'linkDefined':
            return renderLinkDefined(col);
        case 'badge':
            return renderBadge(col);
        case 'document':
            return renderDocument(col);
        case 'gallery':
            return renderGallery(col);
        case 'resources':
            return renderResources(col);
        case 'dropdown':
            return renderDropdown(col);
        default:
            return (row, index, datatable) => {
                const val = getPath(row, col.field);
                return (val !== undefined && val !== null) ? val : Translator.trans("notAvailable", {}, "messages");
            };
    }
}
function renderMoney(col) {
    return (row, index, datatable) => {
        const v = getPath(row, col.field);
        return typeof v === "number" ? numeral(v).format("$0,0.00") : v;
    };
}
function renderMoneyWithBalanceState(col) {
    return (row, index, datatable) => {
        let v = getPath(row, col.field);
        let balanceState = "zeroBalance";
        if (v > 0) {
            balanceState = "positiveBalance";
        }
        else if (v < 0) {
            balanceState = "negativeBalance";
        }
        if (typeof v === "number") {
            v = numeral(v).format("$0,0.00");
        }
        return '<span class="' + balanceState + '">' + v + '</span>';
    };
}
function renderPercentage(col) {
    return (row, index, datatable) => {
        const v = getPath(row, col.field);
        if (typeof v === "number" && Math.abs(v) > Number.EPSILON) {
            return numeral(v).format("0.0%");
        }
        return "0.00%";
    };
}
function renderThumbnail(col) {
    return (row, index, datatable) => {
        const v = getPath(row, col.field);
        return `<a class="kt-media kt-media--xl"><img src="${v}"></a>`;
    };
}
function renderMonths(col) {
    return (row, index, datatable) => {
        const v = getPath(row, col.field);
        return v + ' Monate';
    };
}
function renderLink(col) {
    return (row, index, datatable) => {
        const v = getPath(row, col.field);
        return '<a href="' + window.Router.generate(col.linkPath, { [col.linkParameter]: getPath(row, col.linkValue) }) + '">' + v + '</a>';
    };
}
function renderLinkDefined(col) {
    return (row, index, datatable) => {
        const v = getPath(row, col.field);
        if (typeof col.linkValue == "undefined") {
            return v;
        }
        const link = getPath(row, col.linkValue);
        if (typeof link == "undefined" || link == "k/A") {
            return v;
        }
        return '<a href="' + link + '">' + v + '</a>';
    };
}
function renderBadge(col) {
    return (row, index, datatable) => {
        let v = getPath(row, col.field);
        const color = col.badgeColors[col.badgeColorKeys.indexOf(v)];
        let attributes = '';
        if (col.badgeOutline)
            attributes = 'kt-badge--outline';
        if (!color)
            return v;
        if (typeof row.getStateDate != "undefined" && row.getStateDate != "k/A") {
            if (typeof row.getStatePrefix != "undefined") {
                v += " " + row.getStatePrefix;
            }
            v += " " + row.getStateDate;
        }
        return '<span class="kt-badge ' + attributes + ' kt-badge--' + color + ' kt-badge--inline kt-badge--pill">' + v + '</span>';
    };
}
function renderDocument(col) {
    return (row) => {
        const value = getPath(row, col.field);
        if (value !== undefined
            && value !== ""
            && value !== "k/A"
            && value !== "n/A"
            && /(.*)+(\.\w+)$/.test(value)) {
            const splitPicture = value.split("/");
            const pictureName = splitPicture[splitPicture.length - 1];
            const title = !!row.getPublicResourceName
                && row.getPublicResourceName !== "n/A"
                && row.getPublicResourceName !== "k/A"
                ? row.getPublicResourceName
                : pictureName;
            return renderDocumentButtons(title, value);
        }
        return `
            <span>
                ${Translator.trans("notAvailable", {}, "messages")}
            </span>
        `;
    };
}
function renderGallery(col) {
    return (row) => {
        const gallery = getPath(row, col.field);
        if (Array.isArray(gallery) && gallery.length > 0) {
            const resources = row.getResources;
            const parsedResources = !!resources && resources !== "n/A" && resources !== "k/A"
                ? JSON.parse(resources)
                : undefined;
            const mappedGallery = gallery.map(picture => {
                const splitPicture = picture.split("/");
                const pictureName = splitPicture[splitPicture.length - 1];
                const title = parsedResources
                    ? parsedResources[picture]
                    : pictureName;
                return `
                    <li>
                        ${renderDocumentButtons(title, picture)}
                    </li>`;
            });
            return `<ul>${mappedGallery.join('')}</ul>`;
        }
        return `
            <span>
                ${Translator.trans("notAvailable", {}, "messages")}
            </span>
        `;
    };
}
function renderResources(col) {
    return (row) => {
        const resources = getPath(row, col.field);
        const parsedResources = !!resources && resources !== "n/A" && resources !== "k/A"
            ? JSON.parse(resources)
            : undefined;
        if (!!parsedResources) {
            const resourcePaths = Object.keys(parsedResources);
            const mappedGallery = resourcePaths.map(resourcePath => {
                const resourceTitle = parsedResources[resourcePath];
                return `<li>${renderDocumentButtons(resourceTitle, resourcePath)}</li>`;
            });
            return `<ul>${mappedGallery.join('')}</ul>`;
        }
        return `
            <span>
                ${Translator.trans("notAvailable", {}, "messages")}
            </span>
        `;
    };
}
function renderDropdown(col) {
    return (row) => {
        const defaultColor = 'light';
        const title = getPath(row, col.field);
        if (col.dropdownValues.length === 0) {
            return title;
        }
        let color = col.dropdownColors[col.dropdownColorKeys.indexOf(title)];
        if (!color) {
            color = defaultColor;
        }
        let statuses = '';
        col.dropdownValues.forEach(function (statusTitle, statusValue) {
            const color = col.dropdownColors[col.dropdownColorKeys.indexOf(statusTitle)] ?? defaultColor;
            statuses += `<a type="button" data-entity="${row.id}" data-color="${color}" data-status="${statusValue}" class="dropdown-item js-reminder-status">${statusTitle}</a>`;
        });
        return `
            <div class="dropdown">
                <button id="dropdown-${row.id}" class="btn btn-sm btn-${color} dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-color="${color}">
                ${title}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                   ${statuses}
                </div>
            </div>
        `;
    };
}
function escapeHTML(html) {
    const escape = document.createElement('textarea');
    escape.textContent = html;
    return escape.innerHTML;
}
function renderDocumentButtons(title, url) {
    return `
        <div class="btn-group btn-group-sm" role="group">
            <button type="button" class="btn btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="text-primary">${escapeHTML(title)}</span>
            </button>
            <div class="dropdown-menu">
                <a onclick="window.dv.open('${url}', null);" type="button" class="dropdown-item" data-url="${url}">
                    <i class="fa fa-image"></i> ${Translator.trans("btn.preview", {}, "messages")}
                </a>
                <a class="dropdown-item" href="${url}" download="${title}">
                    <i class="fa fa-download"></i> ${Translator.trans("btn.download", {}, "messages")}
                </a>
            </div>
        </div>
    `;
}
