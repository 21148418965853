import AsideComponent from "../aside-component/asideComponent";
import { DOCUMENT_TYPES } from "../utils";
import { IMAGES_TYPES } from "../utils";
export default class DocumentViewer {
    static TITLE = 'Dokument';
    static ESCAPED_MINUS_SIGN = '%2D';
    static WOPI_API_BASE_URL = "http://ie3-view-wopi.wopi.live.net:808/oh/wopi/files/@/wFileId?wFileId=";
    static WORD_VIEWER_FRAME_URL = "https://ie3-word-view.officeapps.live.com/wv/wordviewerframe.aspx";
    static LANGUAGE_DE = 'de-DE';
    asideComponent;
    constructor() {
        this.asideComponent = AsideComponent.getInstance();
        const that = this;
        that.attach();
    }
    attach() {
        $('.document-viewer').on('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleRequest(event.target);
        });
    }
    initEventListeners() {
        $('.document-viewer').on('load', () => {
            $('.document-viewer-spinner').remove();
        });
    }
    handleRequest(element) {
        const href = $(element).attr('href');
        this.asideComponent.setTitle(DocumentViewer.TITLE);
        if ($(element).data('type') !== undefined) {
            const requestedType = $(element).data('type');
            if (!Object.values(DOCUMENT_TYPES).includes(requestedType)) {
                console.error('unsupported document type', requestedType);
                return;
            }
            this.asideComponent.setContent(DocumentViewer.renderViewer($(element).data('type'), href));
        }
        else {
            console.warn('no document type given in element, assuming the file is a docx');
            this.asideComponent.setContent(DocumentViewer.renderViewer(DOCUMENT_TYPES.DOCX, href));
        }
        this.asideComponent.open();
    }
    open(documentUrl, documentType = DOCUMENT_TYPES.DOCX) {
        this.asideComponent.setTitle(DocumentViewer.TITLE);
        this.asideComponent.setContent(DocumentViewer.renderViewer(documentType, documentUrl));
        this.asideComponent.open();
        this.initEventListeners();
    }
    static renderViewer(type, documentUrl) {
        $("#form_content").addClass("document-viewer-container");
        const extension = type || documentUrl.split('.').pop();
        const imageExtensionList = Object.values(IMAGES_TYPES);
        if (imageExtensionList.indexOf(extension) >= 0) {
            return `
            <div class="document-viewer-spinner d-flex justify-content-center h-100">
                <div class="kt-spinner kt-spinner--primary document-viewer-spinner kt-spinner--lg"></div>
            </div>
            <img class="document-viewer" src="${documentUrl}"">
        `;
        }
        return `
            <div class="document-viewer-spinner d-flex justify-content-center h-100">
                <div class="kt-spinner kt-spinner--primary document-viewer-spinner kt-spinner--lg"></div>
            </div>
            <iframe class="document-viewer" src="${DocumentViewer.getViewerEmbedUrl(type, documentUrl)}" width="100%" height="100%"></iframe>
        `;
    }
    static getViewerEmbedUrl(documentType, documentUrl) {
        if (documentType === DOCUMENT_TYPES.DOCX) {
            return DocumentViewer.getEmbeddedWordViewerUrl(documentUrl);
        }
        else {
            console.warn('cannot view documents of type ' + documentType);
            return documentUrl;
        }
    }
    static getEmbeddedWordViewerUrl(documentUrl, uiLanguage = DocumentViewer.LANGUAGE_DE, resultLanguage = DocumentViewer.LANGUAGE_DE) {
        return DocumentViewer.WORD_VIEWER_FRAME_URL +
            "?embed=1" +
            "&ui=" + uiLanguage +
            "&mscc=1" +
            "&rs=" + resultLanguage +
            "&access_token_ttl=0" +
            "&WOPISrc=" + DocumentViewer.getWOPISource(documentUrl);
    }
    static getWOPISource(documentUrl) {
        return DocumentViewer.escapeDocumentUrl(this.WOPI_API_BASE_URL + documentUrl);
    }
    static escapeDocumentUrl(documentUrl) {
        return encodeURIComponent(documentUrl)
            .split('-').join(DocumentViewer.ESCAPED_MINUS_SIGN);
    }
}
window.dv = new DocumentViewer();
