// jquery
import '../theme/tools/webpack/vendors/global';
import '../theme/tools/webpack/scripts';

// non-theme vendors
import '../theme/src/assets/sass/pages/wizards/wizard-v2.scss';
import '../theme/src/assets/sass/pages/wizards/wizard-v3.scss';
import '../sass/fwc-listing.scss';
import '../sass/administration.scss';

// Uppy styles
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

// custom plugins
import "../plugins/route-generator/routeGenerator";
import '../plugins/conditional-form-elements/conditionalFormElement';
import "../plugins/createable-entity/createableEntity";
import "../plugins/damage-manager/damageManager";
import '../plugins/data-table/dataTable';
import '../plugins/upload-manager/UploadsAdapter';

import '../plugins/aside-component/asideComponent';
import '../plugins/document-viewer/documentViewer';
import '../plugins/document-generator/documentGenerator';

import '../plugins/ancillary-expense-distribution/ancillaryExpenseDistribution';
import '../plugins/meter-readings/meterReadings';
import '../fairwalter/utils/gtag_helper';

// additional scripts
import './main.js';

import Glide from '@glidejs/glide';

import axios from "axios";
// translations
import Translator, {getLocale, isLocale, setLocale,} from 'Services/Translator';

import {loadTranslations} from "./translations";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.jQuery = window.$ = require("jquery");
window.Glide = Glide;

// custom styles
require('../sass/app.scss');

setLocale(document.getElementsByTagName('html')[0].getAttribute('lang'));
loadTranslations(getLocale());

window.Translator = Translator;

if (isLocale('de')) {
    require('bootstrap-select/js/i18n/defaults-de_DE');
}

$(document).ajaxError(function (event, xhr) {
    const json = xhr.responseJSON;
    if (!!json && !!json.redirectUrl) {
        window.location = json.redirectUrl;
    }
});


$(document).ready(function() {
    $('input.custom-file-input[type="file"]').on("change", function() {
        const files = this.files;
        let filenames = [];

        for (let i in files) {
            if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
            }
        }

        const maxNames = 3;
        const nbFiles = files.length;
        let nbOtherFiles = Math.max(0, nbFiles - maxNames);

        if (nbFiles > maxNames) {
            filenames = filenames.slice(0, maxNames);
        }

        $(this)
            .next(".custom-file-label")
            .html(filenames.join(", ") + (nbOtherFiles > 0 ? ` [+${nbOtherFiles} more]` : ''));
    });
});

function removeAllFairwalterColumns() {
    Object.keys(localStorage).forEach(key => {
        if (key.includes('_fairwalter_columns_')) {
            localStorage.removeItem(key);
        }
    });
}

removeAllFairwalterColumns();

var KTAsideSecondary = function() {
    var panel = KTUtil.get('kt_aside_secondary');
    var content1 = KTUtil.get('kt_aside_secondary_tab_1');
    var scroll1 = KTUtil.find(content1, '.kt-aside-secondary__content-body');
    var mobileNavToggler;
    var lastOpenedTab;

    var getContentHeight = function(content) {
        var height;
        var head = KTUtil.find(content, '.kt-aside-secondary__content-head');
        var body = KTUtil.find(content, '.kt-aside-secondary__content-body');

        height = parseInt(KTUtil.getViewPort().height) - parseInt(KTUtil.actualHeight(head)) - 60;

        if (KTUtil.isInResponsiveRange('desktop')) {
            height = height - KTUtil.actualHeight(KTUtil.get('kt_header'));
        } else {
            height = height - KTUtil.actualHeight(KTUtil.get('kt_header_mobile'));
        }

        return height;
    }

    var initNavs = function() {
        $('#kt_aside_secondary_nav a[data-toggle="tab"]').on('click', function (e) {
            if ((lastOpenedTab && lastOpenedTab.is($(this))) && $('body').hasClass('kt-aside-secondary--expanded')) {
                KTLayout.closeAsideSecondary();
            } else {
                lastOpenedTab =  $(this);
                KTLayout.openAsideSecondary();
            }
        });

        $('#kt_aside_secondary_close').on('click', function (e) {
            KTLayout.closeAsideSecondary();
        });

        $('#kt_aside_secondary_nav a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            KTUtil.scrollUpdate(scroll1);
            KTUtil.scrollUpdate(scroll2);
            KTUtil.scrollUpdate(scroll3);
        });

        mobileNavToggler = new KTToggle('kt_aside_secondary_mobile_nav_toggler', {
            target: 'body',
            targetState: 'kt-aside-secondary--mobile-nav-expanded'
        });
    }

    var initContent1 = function() {
        KTUtil.scrollInit(scroll1, {
            disableForMobile: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return getContentHeight(content1);
            }
        });
    }

    return {
        init: function() {
            //initOffcanvas();
            initNavs();
            initContent1();
        }
    };
}();

// Init on page load completed
KTUtil.ready(function() {
    KTAsideSecondary.init();
    $(".creatable-entity").createableEntity();
    $("[data-conditional-discriminator]").conditionalFormElements();
    $(".damage-manager").manageDamages();
});
