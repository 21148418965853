import * as $ from "jquery";
import { render } from "./dataTableFunctions";
import DocumentGenerator from "../document-generator/documentGenerator";
import { getPath } from "../utils";
$.fn.dataTable = function (options) {
    const settings = {
        remoteUrl: null,
        actionUrlSuffix: '',
        elementId: '',
        ...options
    };
    console.warn('@deprecated to be removed', 'assets/plugins/data-table/dataTable.ts:53');
    const getDefinition = function () {
        const cacheKey = '_fairwalter_columns_' + settings.remoteUrl;
        let url = new URL(settings.remoteUrl, window.location.origin);
        url.searchParams.append('_cols', '1');
        let cachedColumns = localStorage.getItem(cacheKey) ? JSON.parse(localStorage.getItem(cacheKey)) : null;
        if (cachedColumns === null) {
            $.ajax({
                url: url.toString(),
                dataType: 'json',
                async: false,
                success: function (data) {
                    localStorage.setItem(cacheKey, JSON.stringify(data));
                    cachedColumns = data;
                }
            });
        }
        return cachedColumns;
    };
    const addFilters = (cols) => {
        cols.filter(c => c.hasFilter).forEach(c => {
            const filter = $('#datatable-filter-prototype').clone();
            filter.removeClass('hidden');
            filter.appendTo($('#datatable-filter-prototype').parent());
            filter.find('.kt-form__label label').html(c.title);
            filter.find('select').data('column', c.filterKey);
            filter.find('select').attr('data-column', c.field);
            c.filterValues.forEach((v, i) => {
                filter.find('select').append($(`<option value="${c.filterValues[i]}">${Translator.trans(c.filterLabels[i], {}, 'messages')}</option>`));
            });
        });
        $(".table-filter").selectpicker();
    };
    const addTemplates = (cols) => {
        cols.forEach(c => {
            if (c.title == 'ID') {
                c.width = 50;
            }
            else {
                c.width = c.width || undefined;
            }
            c.template = render(c);
        });
    };
    const getActionUrl = (action, id) => {
        let url = new URL(window.Router.generate(action.route, { [action.parameter]: id }), window.location.origin);
        let params = new URLSearchParams(action.actionUrlSuffix);
        params.forEach((value, key) => url.searchParams.append(key, value));
        return url.toString();
    };
    const addActions = (cols, actions) => {
        if (actions === undefined || actions === null || Object.keys(actions).length === 0) {
            return cols;
        }
        cols.push({
            field: Translator.trans('table.actions', {}, 'messages'),
            title: Translator.trans('table.actions', {}, 'messages'),
            sortable: !1,
            autoHide: !1,
            overflow: 'visible',
            textAlign: 'center',
            template(row, index, datatable) {
                const id = row.id ? row.id : row.getId;
                let result = '';
                Object.keys(actions).forEach(key => {
                    if (actions[key].items === undefined) {
                        if (actions[key].discriminator === undefined || row[actions[key].discriminator] === true) {
                            const actionUrl = getActionUrl(actions[key], id);
                            let extraAttributes = "";
                            if (actions[key].route && actions[key].route.includes("remove_")) {
                                extraAttributes = "data-remove-element data-remove-id=" + row.id;
                                if (actions[key].data && actions[key].data['type'] == "add-subpayments") {
                                    extraAttributes += " data-add-subpayments=" + row.numberOfSubPayments;
                                }
                            }
                            let icon;
                            if (actions[key]?.text) {
                                icon = actions[key]?.text;
                            }
                            else {
                                icon = `<i class="la la-${actions[key].icon}"></i>`;
                                if (actions[key].icon_font) {
                                    icon = `<i class="${actions[key].icon_font} ${actions[key].icon}"></i>`;
                                }
                                if (actions[key].custom_icon) {
                                    icon = `<i class="${actions[key].custom_icon}"></i>`;
                                }
                            }
                            if (actions[key].generate) {
                                result += `
                                    <a
                                        href="#"
                                        data-generate-document="${actions[key].document_template}"
                                        data-source="${actions[key].document_source}"
                                        data-source-id="${getPath(row, 'property.id')}"
                                        class="${actions[key]?.className}"
                                    >
                                        ${icon}
                                    </a>
                                `;
                            }
                            else {
                                if (actions[key].inSidebar) {
                                    let dataAttributes = '';
                                    if (actions[key].data) {
                                        const data = actions[key].data;
                                        Object.keys(data).forEach(k => {
                                            const v = data[k];
                                            dataAttributes = dataAttributes + ' data-' + k + '="' + v + '"';
                                        });
                                    }
                                    result += `
                                        <a
                                            data-toggle="tab"
                                            href="#kt_aside_secondary_tab_1"
                                            ${extraAttributes}
                                            ${dataAttributes}
                                            role="tab"
                                            class="btn nav-link btn-hover-brand btn-icon btn-pill async-form ${actions[key]?.className}"
                                            data-update-table="${settings.elementId}"
                                            data-href="${actionUrl}"
                                            title="${key}"
                                        >
                                            ${icon}
                                        </a>
                                    `;
                                }
                                else {
                                    result += `
                                        <a
                                            href="${actionUrl}"
                                            ${extraAttributes}
                                            class="btn nav-link btn-hover-brand btn-icon btn-pill ${actions[key]?.className}"
                                            title="${key}"
                                        >
                                            ${icon}
                                        </a>
                                    `;
                                }
                            }
                        }
                    }
                    else {
                        if (actions[key].discriminator === undefined || row[actions[key].discriminator] === true) {
                            result += `
                                <div class="dropdown">
                                <a href="javascript:;" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                                                                <i class="la la-download"></i>
                                                            </a>
                                  <div class="dropdown-menu dropdown-menu-right">
                                  `;
                            const items = actions[key].items;
                            Object.keys(actions[key].items).forEach(itemKey => {
                                let showAction = false;
                                const discriminator = items[itemKey].discriminator ?? undefined;
                                if (discriminator instanceof Array) {
                                    showAction = true;
                                    discriminator.forEach((value) => {
                                        if (row[value] !== true) {
                                            showAction = false;
                                            return false;
                                        }
                                    });
                                }
                                else {
                                    showAction = discriminator === undefined || row[discriminator] === true;
                                }
                                if (showAction) {
                                    const actionUrl = getActionUrl(items[itemKey], id);
                                    let extraAttributes = "";
                                    if (actions[key].route && actions[key].route.includes("remove_")) {
                                        extraAttributes = "data-remove-element data-remove-id=" + row.id;
                                        if (actions[key].data && actions[key].data['type'] == "add-subpayments") {
                                            extraAttributes += " data-add-subpayments=" + row.id;
                                        }
                                    }
                                    let icon = `<i class="la la-${items[itemKey].icon}"></i>`;
                                    if (items[itemKey].icon_font) {
                                        icon = `<i class="${items[itemKey].icon_font} ${items[itemKey].icon}"></i>`;
                                    }
                                    if (items[itemKey].custom_icon) {
                                        icon = `<i class="${items[itemKey].custom_icon}"></i>`;
                                    }
                                    if (items[itemKey].generate) {
                                        result += `
                                            <a
                                                href="#"
                                                onclick="event.preventDefault(); window.dg.handleRequest(this);"
                                                data-generate-document="${items[itemKey].document_template}"
                                                data-source="${items[itemKey].document_source}"
                                                data-source-id="${getPath(row, 'property.id')}"
                                            >
                                                ${icon}
                                            </a>
                                        `;
                                    }
                                    else {
                                        if (items[itemKey].inSidebar) {
                                            let dataAttributes = '';
                                            if (items[itemKey].data) {
                                                const data = items[itemKey].data;
                                                Object.keys(data).forEach(k => {
                                                    const v = data[k];
                                                    dataAttributes = dataAttributes + ' data-' + k + '="' + v + '"';
                                                });
                                            }
                                            result += `
                                                <a
                                                    data-toggle="tab"
                                                    href="#kt_aside_secondary_tab_1"
                                                    ${extraAttributes}
                                                    ${dataAttributes}
                                                    role="tab"
                                                    class="dropdown-item async-form"
                                                    data-update-table="${settings.elementId}"
                                                    data-href="${actionUrl}"
                                                    title="${itemKey}"
                                                >
                                                    ${icon} ${itemKey}
                                                </a>
                                            `;
                                        }
                                        else {
                                            result += `
                                                <a
                                                    href="${actionUrl}"
                                                    ${extraAttributes}
                                                    class="dropdown-item"
                                                    title="${itemKey}"
                                                >
                                                    ${icon} ${itemKey}
                                                </a>
                                            `;
                                        }
                                    }
                                }
                            });
                            result += `
                                  </div>
                                </div>`;
                        }
                    }
                });
                return result;
            }
        });
        return cols;
    };
    const initDataTable = (e, cols) => {
        const url = settings.remoteUrl;
        let querySearch = {};
        let searchVal = $("#generalSearch2").val();
        if (searchVal != "") {
            querySearch = { generalSearch: searchVal };
        }
        return $(e).KTDatatable({
            translate: JSON.parse(localStorage.getItem('dataTableTranslation')),
            data: {
                type: 'remote',
                source: {
                    read: {
                        url,
                        map(raw) {
                            let dataSet = raw;
                            if (typeof raw.data !== 'undefined') {
                                dataSet = raw.data;
                            }
                            return dataSet;
                        },
                        params: {
                            query: querySearch
                        }
                    }
                },
                pageSize: 10,
                serverPaging: !0,
                serverFiltering: !0,
                serverSorting: !0,
            },
            layout: {
                scroll: 1,
                footer: !1
            },
            sortable: !0,
            pagination: !0,
            columns: cols,
        });
    };
    return this.each(function () {
        const definition = getDefinition();
        addFilters(definition.cols);
        addTemplates(definition.cols);
        definition.cols = addActions(definition.cols, definition.actions);
        const t = initDataTable($(this), definition.cols);
        if (window.dataTables === undefined) {
            window.dataTables = [];
        }
        window.dataTables[settings.elementId] = t;
        $(t).on('kt-datatable--on-ajax-done', function (e) {
            const dg = new DocumentGenerator();
        });
        $('.table-filter').on('change', function () {
            if ($(this).val() !== '') {
                t.search($(this).val().toString().toLowerCase(), $(this).attr('data-column'));
            }
        });
        $('#generalSearch2').on('keyup', function () {
            t.search($(this).val(), 'generalSearch');
        });
    });
};
