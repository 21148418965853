import moment from 'moment';
import { setDebugVariable } from '@f2w/utils';
import BaseTranslator from 'bazinga-translator';
import { setLocale as setRouterLocale } from '../Router';
import * as ReactDatePicker from 'react-datepicker';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import numeral from 'numeral';
import 'numeral/locales/de-ch';
ReactDatePicker.registerLocale('de', de);
ReactDatePicker.registerLocale('fr', fr);
Object.assign(numeral.locales['de-ch'], {
    delimiters: {
        thousands: '\'',
        decimal: '.'
    },
});
export const availableLocales = ['de', 'en'];
const numeralLocaleMap = {
    'de': 'de-ch',
    'fr': 'de-ch'
};
export const defaultLocale = 'de';
export const isLocale = (locale) => {
    return locale === getLocale();
};
export const hasLocale = (locale) => {
    return availableLocales.includes(locale);
};
export const getLocale = () => {
    return (BaseTranslator.locale || defaultLocale);
};
export const getCurrency = () => {
    return numeral.localeData().currency.symbol ?? 'CHF';
};
export const setLocale = (locale) => {
    if (hasLocale(locale)) {
        BaseTranslator.locale = locale;
        moment.locale(locale);
        setRouterLocale(locale);
        ReactDatePicker.setDefaultLocale(locale);
        numeral.locale(numeralLocaleMap[locale] ?? locale);
        window['moment']?.locale(locale);
    }
};
export const updateLocale = (locale, localeData) => {
    setLocale(locale);
    if (localeData) {
        Translator.fromJSON(localeData);
    }
};
export const translate = (id, ...args) => {
    return Translator.trans(id, args.shift() ?? {}, (args.shift() ?? 'messages'), args.shift() ?? null);
};
export { translate as trans, numeral };
export const transChoice = (id, ...args) => {
    return Translator.transChoice(id, args.shift() ?? 0, args.shift() ?? {}, (args.shift() ?? 'messages'), args.shift() ?? null);
};
export const Translator = Object.assign(BaseTranslator, {
    translate,
    numeral
});
setDebugVariable({ fw_Translator: Translator });
