import { sendEvents } from 'Services/GtmServices';
import { isDevEnv } from '@f2w/utils';
const STORAGE_KEY = 'fw.gtag.events';
const getCache = (clear) => {
    const data = (JSON.parse(localStorage.getItem(STORAGE_KEY)) || {});
    clear && localStorage.removeItem(STORAGE_KEY);
    return data;
};
const setCache = (data) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};
function fwGtmForm({ id, events }) {
    const $form = jQuery(`#${id}`).closest('form').first();
    if ($form.length) {
        if (isDevEnv())
            console.debug('[fwGtmForm.register] %o', id, events);
        $form.on('submit', (e) => {
            if (isDevEnv())
                console.debug('[fwGtmForm.cacheEvents] %o', id, events);
            setCache({ id, events });
        });
    }
}
$(() => {
    const { id, events } = getCache(true);
    if (id && !document.getElementById(id)) {
        if (isDevEnv())
            console.debug('[fwGtmForm.sendEvents] %o', id, events);
        sendEvents(events);
    }
});
window.fwGtmForm = fwGtmForm;
