import { generate } from './Router';
import axios from 'axios';
export const getGlobalContext = () => {
    if (!!globalThis.globalContext) {
        return globalThis.globalContext;
    }
    else {
        return {
            activeRoute: "",
            scope: null,
            features: [],
            roles: [],
            portfolioData: {},
            navFavourites: {
                "properties": [206, 208, 12],
                "rentalObjects": [739, 740, 482],
            },
            patchNote: null,
            appVersion: null,
            productFeatures: [],
            user: null,
            customer: null,
            limits: null,
            usages: null,
        };
    }
};
export function saveFavouritesNew(favouriteData) {
    const url = generate('favourites_save');
    return axios.post(url, favouriteData);
}
export function hasExceededNbUsersLimit() {
    const { limits = null, usages = null } = getGlobalContext();
    if (!limits || limits.nbUsers === null) {
        return false;
    }
    return limits.nbUsers <= usages.nbUsers;
}
