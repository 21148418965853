export const getPath = function (obj, path) {
    var parts = path.split('.');
    while (parts.length) {
        obj = obj[parts.shift()];
    }
    return obj;
};
export const initRestoreTabs = () => {
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        localStorage.setItem('activeTab', $(e.target).attr('href'));
    });
    const activeTab = localStorage.getItem('activeTab');
    let $tabsContainer = $('#kt_content_body .nav-tabs').first();
    if ($tabsContainer.length <= 0) {
        return;
    }
    let $tab = activeTab ? $tabsContainer.find('a[href="' + activeTab + '"]') : null;
    const isTabValid = $tab === null || !$tab.closest('.nav-item').hasClass('is-invalid');
    if (isTabValid) {
        const $invalidNavItem = $tabsContainer
            .find('.nav-item.is-invalid')
            .first();
        const $invalidTab = $invalidNavItem.find('a[data-toggle="tab"]').first();
        if ($invalidTab.length !== 0) {
            $tab = $invalidTab;
        }
    }
    if ($tab !== null && $tab.length > 0) {
        $tab.tab('show');
    }
    else {
        localStorage.removeItem('activeTab');
    }
};
export const generateFeedbackErrorLabel = (header, text) => {
    return `
        <span class="invalid-feedback d-block">
            <span class="d-block">
                <span class="form-error-icon badge badge-danger text-uppercase">${header}</span>
                <span class="form-error-message">${text}</span>
            </span>
        </span>
    `;
};
export var DOCUMENT_TYPES;
(function (DOCUMENT_TYPES) {
    DOCUMENT_TYPES["DOCX"] = "docx";
})(DOCUMENT_TYPES || (DOCUMENT_TYPES = {}));
export var IMAGES_TYPES;
(function (IMAGES_TYPES) {
    IMAGES_TYPES["JPEG"] = "jpeg";
    IMAGES_TYPES["JPG"] = "jpg";
    IMAGES_TYPES["GIF"] = "gif";
    IMAGES_TYPES["PNG"] = "png";
    IMAGES_TYPES["HEIC"] = "heic";
})(IMAGES_TYPES || (IMAGES_TYPES = {}));
