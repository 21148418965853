import { isDevEnv } from '@f2w/utils';
export default class AsideComponent {
    static instance;
    static getInstance() {
        if (!AsideComponent.instance) {
            AsideComponent.instance = new AsideComponent();
        }
        return AsideComponent.instance;
    }
    constructor() {
        if (isDevEnv())
            console.debug('initialized Aside Component');
    }
    setTitle(title) {
        $("#sidebar-header").html(title);
    }
    setContent(data) {
        $('#form_content').html(data);
    }
    open() {
        KTLayout.openAsideSecondary();
    }
    close() {
        KTLayout.closeAsideSecondary();
    }
}
const ac = AsideComponent.getInstance();
