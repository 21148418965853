import * as $ from "jquery";
$.fn.meterReadings = function () {
    return this.each(function () {
        const $eValue = $(this);
        const id = $eValue.attr("id");
        const idPrefix = id.substring(0, id.length - "value".length);
        const $eCurrentReading = $("#" + idPrefix + "currentValue");
        const $ePreviousReading = $("#" + idPrefix + "previousValue");
        $ePreviousReading.on("change", (e) => {
            calculateConsumption();
        });
        $eCurrentReading.on("change", (e) => {
            calculateConsumption();
        });
        $eValue.on("change", (e) => {
            calculateCurrentReading();
        });
        const calculateConsumption = () => {
            const previousReading = Number($ePreviousReading.val().toString());
            const currentReading = Number($eCurrentReading.val().toString());
            if (isNaN(previousReading) || isNaN(Number(currentReading))) {
                return;
            }
            $eValue.val(currentReading - previousReading);
        };
        const calculateCurrentReading = () => {
            const previousReading = Number($ePreviousReading.val().toString());
            const value = Number($eValue.val().toString());
            if (isNaN(previousReading) || isNaN(Number(value))) {
                return;
            }
            $eCurrentReading.val(previousReading + value);
        };
        calculateConsumption();
    });
};
