import * as numeral from 'numeral';
import Translator from "bazinga-translator";
import * as KTLayout from "../theme/src/assets/js/global/layout/layout";
import {initRestoreTabs} from "../plugins/utils";

require('../../public/js/jquery.collection');
require('form-serializer/jquery.serialize-object');

function colorAvatars() {
    $(".kt-random-color").each((i, e) => {
        const backgroundColor = stringToHslColor($(e).data('name'), 30, 80);
        const textColor = stringToHslColor($(e).data('name'), 30, 40);
        $(e).css('color', textColor);
        $(e).css('background-color', backgroundColor);
    });
}

window.closeAsyncFormContainer = () => {
    KTLayout.closeAsideSecondary();
};

function registerAsyncFormHandler() {
    $("body").off("click", ".async-form");
    $("body").on("click", ".async-form", function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).removeClass('active');

        const href = $(this).data('href');
        if ($(this).data('type') === 'pdf') {
            window.dv.open(href, 'pdf');
            return;
        }

        let updateTableID = null;
        if ($(this).data('update-table')) {
            updateTableID = $(this).data('update-table');
        }

        let doReload = false;
        if ($(this).data('reload')) {
            doReload = true;
        }

        const title = $(this).attr('title');

        window.loadAsyncForm(href, title, updateTableID, doReload);
    });
}

function loadAsyncForm(href, title, updateTableID=null, doReload=false)
{
    $(".kt-aside-secondary__content-head").html(title);
    $.get(href, function(data) {
        $('#form_content').html(data);
        KTLayout.openAsideSecondary();
        $('#form_content form').attr('action', href);
        $('#form_content form').attr('data-update-table', updateTableID);

        if (doReload) {
            $('#form_content form').attr('data-reload', 'true');
        }

        $('.kt_selectpicker').selectpicker();
        //window.initTownTypeahead();
    });
}
window.loadAsyncForm = loadAsyncForm;


Object.getPath = function(obj, path) {
    var parts = path.split('.');
    while (parts.length) {
        obj = obj[parts.shift()];
    }
    return obj;
};

window.initDataTable = (element, url, actionUrlSuffix, elementID = '') => {
    $(document).ready(() => {
        let cachedColumns = localStorage.getItem('_fairwalter_columns_' + url);

        if (!cachedColumns) {
            let colsUrl = new URL(url, window.location.origin);
            colsUrl.searchParams.append('_cols','1');

            $.ajax({
                url: colsUrl.toString(),
                dataType: 'json',
                async: false,
                success: function(data) {
                    localStorage.setItem('_fairwalter_columns_' + url, JSON.stringify(data));
                    cachedColumns = data;
                }
            });
        } else {
            cachedColumns = JSON.parse(cachedColumns);
        }

        let cols = cachedColumns.cols;
        const actions = cachedColumns.actions;

        cols.forEach(col => {
            if (col.hasFilter) {
                const filter = $('#datatable-filter-prototype').clone();
                filter.removeClass('hidden');
                filter.appendTo($('#datatable-filter-prototype').parent());
                filter.find('.kt-form__label label').html(col.title);
                filter.find('select').data('column', col.filterKey);
                filter.find('select').attr('id', col.field);
                col.filterValues.forEach((v, i) => {
                    filter.find('select').append($(`<option value="${col.filterValues[i]}">${col.filterLabels[i]}</option>`));
                });
            }
        });


        // $('.table-filter').selectpicker();

        const cols2 = [];
        cols.forEach(col => {
            if (col.renderFunction === 'thumbnail') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    return `<a class="kt-media kt-media--xl"><img src="${v}"></a>`;
                };
            } else if(col.renderFunction === 'money') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    return 'CHF ' + numeral(v).format('0\'0.00');
                };
            } else if (col.renderFunction === 'months') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    return v + ' Monate';
                };
            } else if (col.renderFunction === 'iban') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    return !v || v.trim() === "" ? v : v.replace(/\s+/g, '').match(/.{1,4}/g).join(' ');
                };
            } else if (col.renderFunction === 'link') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    return '<a href="' + window.Router.generate(col.linkPath, { [col.linkParameter] : Object.getPath(row, col.linkValue) }) + '">' + v + '</a>';
                };
            } else if (col.renderFunction === 'badge') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    const color = col.badgeColors[col.badgeColorKeys.indexOf(v)];

                    let attributes = '';
                    if (col.badgeOutline) attributes = 'kt-badge--outline';

                    if (!color) return v;

                    return '<span class="kt-badge ' + attributes + ' kt-badge--' + color + ' kt-badge--inline kt-badge--pill">' + v + '</span>';
                };
            } else if (col.renderFunction === 'linkDefined') {
                col.template = (row, index, datatable) => {
                    const v = Object.getPath(row, col.field);
                    if (typeof col.linkValue == "undefined") {
                        return v;
                    }
                    const link = Object.getPath(row, col.linkValue);
                    if (typeof link == "undefined" || link == "k/A") {
                        return v;
                    }
                    return '<a href="' + link + '">' + v + '</a>';
                };
            }
            cols2.push(col);
        });

        cols = cols2;

        cols.push({
            field: Translator.trans('table.actions', {}, 'messages'),
            title: Translator.trans('table.actions', {}, 'messages'),
            sortable: false,
            width: 130,
            autoHide: false,
            overflow: 'visible',
            textAlign: 'center',
            template(row, index, datatable) {
                const objectId = row['id'] ? row['id'] : row['getId'];

                let result = '';
                Object.keys(actions).forEach(key => {

                    if (actions[key].discriminator !== undefined && row[actions[key].discriminator] === false) {
                        return;
                    }

                    const classNameExtra = (actions[key].confirmationRequired) ? "confirmation-required" : "";


                    let icon = `<i class="la la-${actions[key].icon}"></i>`;
                    if (actions[key].icon_font) {
                        icon = `<i class="${actions[key].icon_font} ${actions[key].icon}"></i>`;
                    }

                    if (actions[key].inSidebar) {
                        result += `<a data-toggle="tab" href="#kt_aside_secondary_tab_1" role="tab" class="btn nav-link btn-hover-brand btn-icon btn-pill async-form ${classNameExtra}" data-update-table="${elementID}" data-href="${window.Router.generate(actions[key].route, { [actions[key].parameter]: objectId})}?${actionUrlSuffix}" title="${key}">${icon}</a>`;
                    } else {
                        result += `<a href="${window.Router.generate(actions[key].route, { [actions[key].parameter]: objectId})}?${actionUrlSuffix}" class="btn nav-link btn-hover-brand btn-icon btn-pill ${classNameExtra}" title="${key}">${icon}</a>`;
                    }
                });
                return result;
            }
        });
        $.ajaxSetup({ cache: false });
        const datatable = element.KTDatatable(
            {
                translate: JSON.parse(localStorage.getItem('dataTableTranslation')),
                data: {
                    type: 'remote',
                    source: {
                        read: {
                            url: !!actionUrlSuffix ? `${url}${(url.includes('?') ? '&': '?')}${actionUrlSuffix}` : url,
                            map(raw) {
                                let dataSet = raw;
                                if (typeof raw.data !== 'undefined') {
                                    dataSet = raw.data;
                                }
                                return dataSet;
                            }
                        }
                    },
                    pageSize: 10,
                    serverPaging: !0,
                    serverFiltering: !0,
                    serverSorting: !0,
                },
                layout: {
                    scroll: 1,
                    footer: !1
                },
                sortable: !0,
                pagination: !0,
                columns: cols
            });

        if (window.dataTables === undefined) {
            window.dataTables = [];
        }

        window.dataTables[elementID] = datatable;

        $('.table-filter').on('change', function() {
            if ($(this).val() !== '') {
                datatable.search($(this).val().toLowerCase(), $(this).data('column'));
            }
        });

        $('#generalSearch2').keyup(function() {
            datatable.search($(this).val(), 'generalSearch');
        });
    });
};


function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

window.sortDates = function sortDates(data, sort, column) {
    const notAvailableLabel = Translator.trans('notAvailable', {}, 'messages');
    const format = 'dd.MM.yyyy';
    const field = column['field'];

    return $(data).sort(function(a, b) {
        const aField = (!!a[field] && a[field] !== notAvailableLabel) ? a[field] : '01.01.0001';
        const bField = (!!b[field] && b[field] !== notAvailableLabel) ? b[field] : '01.01.0001';
        const diff = moment(aField, format).diff(moment(bField, format));

        if (sort === 'asc') {
            return diff > 0 ? 1 : diff < 0 ? -1 : 0;
        } else {
            return diff < 0 ? 1 : diff > 0 ? -1 : 0;
        }
    });
};

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
window.copyTextToClipboard = function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

function initSelectPicker()
{
    $('[data-toggle="popover"]').popover();
    $('.kt_selectpicker').selectpicker();

    $('select.kt_selectpicker_ajax')
        .selectpicker({
            liveSearch: true
        })
        .ajaxSelectPicker({
        })
    ;
}

$(document).ready(() => {
    initSelectPicker();
    initRestoreTabs();
    registerAsyncFormHandler();
    colorAvatars();
    $("[data-conditional-discriminator]").conditionalFormElements();

    const $collections = $(".collection");
    if ($collections.length > 0) {
        $collections.collection();
    }

    $("[data-on-change-submit='1']").change(function(e) {
        $(e.target).parentsUntil('form#select_account').submit();
    });

    $("li[data-warn-unless-scope-is='property'] a").click(function(e) {

        if (window.scopeLevel === 'portfolio') {
            e.preventDefault();

            swal.fire({
                title: 'Bitte wählen Sie eine Liegenschaft',
                text: "Diese Aktion existiert nur auf Liegenschaftsebene, Sie befinden sich derzeit aber auf der Ebene des Portfolios.",
                icon: 'warning',
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ok",
            }).then (function (result) {

            });

        } else if(window.scopeLevel === 'rentalObject') {
            e.preventDefault();
            swal.fire({
                title: 'Bitte wählen Sie eine Liegenschaft',
                text: "Diese Aktion existiert nur auf Liegenschaftsebene, Sie befinden sich derzeit aber auf der Ebene des Mietobjekts.",
                icon: 'warning',
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ok",
            }).then (function (result) {

            });

        }
    });

    $('form :submit').on("click", function () {
        const $form = $(this).closest('form');

        $("input[required]:hidden", $form).each(function() {
            $(this).removeAttr("required");
        });
    });
});
